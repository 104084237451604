<template>
    <section>
        <navbar-admin icon="icon-calendar-multiselect" />
        <div class="row justify-content-end d-none">
            <div class="col-auto">
                <button type="button" class="btn mx-2 btn-general f-12 h-32px text-white" @click="verDetalle"> Ver detalle </button>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="col-12">
                <FullCalendar ref="fullCalendar" :options="calendarOptions" class="fullcalendar-styles"/>
            </div>
        </div>
        <modal-ver-detalle ref="modalVerDetalle" />
    </section>
</template>

<script>
import moment from 'moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components:{
        modalVerDetalle: () => import('./partials/modalVerDetalle.vue'),
        // modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
        // modalDetalleTarea: () => import('./partials/modalDetalleTarea.vue'),
    },
    data() {
        return {
            inputSearch:'',
            mostrarRequierenPago: false,
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'dayGridMonth',
                locale: 'es',
                selectable: true,
                height: 'auto',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                },
                dayHeaderFormat:{
                    weekday: 'long',
                },
                buttonText:{
                    today: 'Hoy',
                    week: 'Semana',
                    month: 'Mes',
                },
                dateClick: function(info) {
                    alert('clicked ' + info.dateStr);
                },              
                // eventsSet:function(info){
                //     console.log("sfmlksmfsmdklñfsdmcfslñ kpoa kpoakpo awkdpoak foak", info);
                //     alert('clicked ')
                // },
                eventsSet: () => {
                    this.listarCabecera()
                },
                eventClick: this.verDetalle,
                dayMaxEvents: true,
                events: []
            },
            id_equipo:{},
            id_equipos_modelos:{}
        }
    },
    computed: {
        ...mapGetters({
            calendarioMes: 'calendario/calendario/calendarioMes',
            cabecera: 'calendario/calendario/cabecera',
            equipos: 'calendario/calendario/equipos',
            fechaCalendario: 'calendario/calendario/fechaCalendario',


        }),
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.mes'
        ]);
        this.setData()
    },
    methods: {
        ...mapActions({
            Action_get_calendario_mes: 'calendario/calendario/Action_get_calendario_mes',
            Action_get_cabecera_calendario: 'calendario/calendario/Action_get_cabecera_calendario',
        }),
        ...mapMutations({
            setFechaCalendario: 'calendario/calendario/setFechaCalendario',
        }),
        verDetalle(info){
            let eventObj = info.event;
            this.$refs.modalVerDetalle.toggle(eventObj)
        },
        async setData(){
            this.calendarOptions.events = this.calendarioMes
        },
        async listarCabecera(){
            const fecha = await this.$refs.fullCalendar.getApi().getDate()
            this.setFechaCalendario(moment(fecha).format('YYYY-MM-DD'))
            this.equipos.map(e => {
                if (this.cabecera.id_producto == e.id && e.tabla == 'productos') {
                    this.id_equipo = {id_producto:e.id, id_ops:e.id_ops}
                }else if(this.cabecera.id_producto  == e.id && e.tabla == 'equipos_modelos'){
                    this.id_equipos_modelos = {id_equipos_modelos:e.id, id_ops:e.id_ops}
                }
            })

            const payload = {
                mes:moment(fecha).format('MM'),
                anio:moment(fecha).format('YYYY'),
                id_equipo:!_.isEmpty(this.id_equipo) ? this.id_equipo : null,
                id_equipos_modelos:!_.isEmpty(this.id_equipos_modelos) ? this.id_equipos_modelos : null,
            }
            await this.Action_get_cabecera_calendario(payload)
            await this.Action_get_calendario_mes(payload)


        }

    },
}
</script>

<style>

</style>